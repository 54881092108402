import Container from "react-bootstrap/Container";
import HeaderImage from "../img/myked_header_850.png";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
export default function Header() {
  return (
    <header>
      <Navbar bg="light" fixed="top" id="navbar" className="m-0 p-0">
        <nav
          style={{ justifyContent: "center" }}
          class="navbar navbar-expand-lg"
        >
          <a class="navbar-brand p-3" href="#home">
            <img src={HeaderImage} className="img-responsive" />
          </a>

          <ul
            style={{ justifyContent: "center" }}
            class="navbar-nav mr-auto mt-2 mt-lg-0"
          >
            <li class="nav-item">
              <a class="nav-link" data-to-scrollspy-id="home" href="#home">
                Home
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-to-scrollspy-id="about" href="#about">
                About
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-to-scrollspy-id="projects"
                href="#projects"
              >
                Projects
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-to-scrollspy-id="contact"
                href="#contact"
              >
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </Navbar>
    </header>
  );
}

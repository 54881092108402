import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { useRef, useEffect } from "react";
const photoPage = "http://photo.myked.com";
export default function Introduction() {
  const canvasRef = useRef();
  const canvasParent = useRef();

  useEffect(() => {
    startVisualizer();
  }, []);

  function startVisualizer() {
    const canvas = canvasRef.current;

    const ctx = canvas.getContext("2d");
    console.log(canvas);
    const parentWidth = canvasParent.current.clientWidth;
    let canvasOffset = canvas.getBoundingClientRect();
    let offsetX = 8;
    let offsetY = 5;
    console.log(canvasOffset);
    if (parentWidth > 1500) {
      canvas.width = 1500;
      offsetX = 5;
    } else {
      canvas.width = parentWidth;
    }
    let sizeMultiplier = 0;
    let outerLimit = 0;
    if (parentWidth < 350) {
      console.log("parentWidth < 500:", parentWidth);
      sizeMultiplier = 1.5;
      outerLimit = 2;
    }
    if (parentWidth > 350) {
      console.log("parentWidth < 500:", parentWidth);
      sizeMultiplier = 3;
      outerLimit = 4.5;
    }
    if (parentWidth > 500) {
      sizeMultiplier = 5;
      outerLimit = 10;
    }
    if (parentWidth > 700) {
      sizeMultiplier = 6.5;
      outerLimit = 13;
    }
    if (parentWidth > 1500) {
      sizeMultiplier = 12;
      outerLimit = 26;
    }
    canvas.height = 40 * sizeMultiplier;

    // console.log(canvas);
    let frameCounter = 0;
    const pointColor = "orange";

    let fontName = "squaredance10";
    let fontSize = 12;
    let particleArray = [];
    let offsetText1 = fontSize;
    let offsetText2 = fontSize * 2;
    let offsetText3 = fontSize * 3;
    let textXY = [];
    let textZone = { x1: 0, x2: 1000, y1: 0, y2: 400 };
    // mouse handler
    const mouse = {
      x: null,
      y: null,
      radius: 0,
    };
    window.addEventListener("mousemove", function (event) {
      let canvasOffset = canvas.getBoundingClientRect();
      mouse.x = event.x - canvasOffset.left;
      mouse.y = event.y - canvasOffset.top;
      mouse.moved = 0;
      mouse.radius = 200;
    });
    window.addEventListener("touchmove", function (event) {
      mouse.x = event.x;
      mouse.y = event.y;
      mouse.moved = 0;
      mouse.radius = 200;
    });
    window.addEventListener("touchstart", function (event) {
      mouse.x = event.x;
      mouse.y = event.y;
      mouse.moved = 0;
      mouse.radius = 200;
    });
    window.addEventListener("touchmove", function (event) {
      mouse.x = event.x;
      mouse.y = event.y;
      mouse.moved = 0;
      mouse.radius = 200;
    });
    window.addEventListener("mousedown", function (event) {
      mouse.x = event.x;
      mouse.y = event.y;
      mouse.moved = 0;
      mouse.radius = 200;
    });
    ctx.fillStyle = "red";
    ctx.font = "8px cubicfive18";
    ctx.fillText("-", 0, 30);

    // ctx.strokeRect(textZone.x1, textZone.y1, textZone.x2, textZone.x);
    // ctx.clearRect(0, 0, canvas.width, canvas.height);

    textXY = ctx.getImageData(
      textZone.x1,
      textZone.y1,
      textZone.x2,
      textZone.y2
    );

    class Particle {
      constructor(x, y, color) {
        this.x = x;
        this.y = canvas.height / 2;
        this.size = 3;
        this.baseX = x;
        this.baseY = y;
        this.density = Math.ceil(Math.random() * 10) + 1;
        this.color = color;
        color ? (this.color = color) : (this.color = "red");
      }
      draw() {
        ctx.fillStyle = this.color;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size / 2, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();
      }
      update() {
        let dx = mouse.x - this.x;
        let dy = mouse.y - this.y;
        let distance = Math.sqrt(dx * dx + dy * dy);
        let forceDirectionX = dx / distance;
        let forceDirectionY = dy / distance;
        let maxDistance = mouse.radius;
        let force = (maxDistance - distance) / maxDistance;
        let directionX = forceDirectionX * force * this.density;
        let directionY = forceDirectionY * force * this.density;
        if (distance < mouse.radius) {
          this.x -= directionX;
          this.y -= directionY;
          this.size = 3;
        } else {
          if (this.x !== this.baseX) {
            let dx = this.x - this.baseX;
            this.x -= dx / 10;
          }
          if (this.y !== this.baseY) {
            let dy = this.y - this.baseY;
            this.y -= dy / 10;
          }
          this.size = 3;
        }
      }
    }
    function init() {
      particleArray = [];
      for (let y = 0, y2 = textXY.height; y < y2; y++) {
        for (let x = 0, x2 = textXY.width; x < x2; x++) {
          if (textXY.data[y * 4 * textXY.width + x * 4 + 3] > 128) {
            let positionX = x + offsetX;
            let positionY = y + offsetY;
            particleArray.push(
              new Particle(
                positionX * sizeMultiplier,
                positionY * sizeMultiplier
              )
            );
          }
        }
      }
      //   console.log(particleArray);
    }
    init();

    function animate() {
      frameCounter++;
      mouse.moved++;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < particleArray.length; i++) {
        particleArray[i].draw();
        particleArray[i].update();
      }
      redrawPoints();
      conjunctionJunction();
      // console.log("current lines:", );
      requestAnimationFrame(animate);
      if (mouse.moved > 40) {
        mouse.radius -= mouse.radius * 0.03;
      }
    }
    animate();
    function redrawPoints() {
      // console.log(frameCounter);
      if (frameCounter === 20) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.font = fontSize + "px " + fontName;
        ctx.fillStyle = "white";
        ctx.fillText("I LOVE making", 0, offsetText1);
        ctx.fillText(" things work!", 0, offsetText2);
        ctx.fillText("", 0, offsetText3);

        // ctx.strokeRect(0, 0, 200, 100);
        textXY = ctx.getImageData(
          textZone.x1,
          textZone.y1,
          textZone.x2,
          textZone.y2
        );
        particleArray = [];
        for (let y = 0, y2 = textXY.height; y < y2; y++) {
          for (let x = 0, x2 = textXY.width; x < x2; x++) {
            if (textXY.data[y * 4 * textXY.width + x * 4 + 3] > 128) {
              let positionX = x + offsetX;
              let positionY = y + offsetY;
              particleArray.push(
                new Particle(
                  positionX * sizeMultiplier,
                  positionY * sizeMultiplier,
                  pointColor
                )
              );
            }
          }
        }

        // ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
    }
    function conjunctionJunction() {
      let opacityValue;
      let linesDrawn = 0;
      for (let a = 0; a < particleArray.length; a++) {
        for (let b = 0; b < particleArray.length; b++) {
          let dx = particleArray[a].x - particleArray[b].x;
          let dy = particleArray[a].y - particleArray[b].y;
          let distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < outerLimit) {
            linesDrawn++;
            opacityValue = 1 - distance / outerLimit;
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(particleArray[a].x, particleArray[a].y); // Begin first sub-path
            ctx.lineTo(particleArray[b].x, particleArray[b].y);
            let distDimmer = 255 * dx;
            ctx.strokeStyle = `rgba(255,${distDimmer / 2},${
              distDimmer / 2
            },${opacityValue}`;
            ctx.stroke();
          }
        }
      }
      return linesDrawn;
    }
  }
  return (
    <Container
      id="home"
      className="p-12"
      style={{ maxWidth: "1800px", paddingTop: "25vh", paddingBottom: "20vh" }}
    >
      <div id="canvasParent" className="p-3 fluid" ref={canvasParent}>
        <div
          className="
          text-center align-middle
          
          "
          style={{
            fontFamily: "squaredance10",
          }}
        >
          <canvas id="canvas1" ref={canvasRef} />
        </div>
        <br />
        <br />
        <p className="text-center align-middle">
          Hello! My name is Myke D.
          <br /> I am an award-winning <a href={photoPage}>
            content producer
          </a>{" "}
          and <a href="#projects">developer</a> from Minnesota.
          <br />
        </p>
      </div>
    </Container>
  );
}

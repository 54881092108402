// import Cell from "./Cell";
function sketch2(p5) {
  let cols, rows;
  const w = 20;
  const solveStack = [];
  const grid = [];
  const stack = [];
  const paths = [];
  //randomly generate rgb color values for the maze
  function getRandomColor() {
    var r = Math.round(Math.random() * 200) + 55;
    var g = Math.round(Math.random() * 200) + 55;
    var b = Math.round(Math.random() * 127) + 55;

    // let r = random(255);
    // let g = random(255);
    // let b = random(255);
    let a = 255;
    return [r, g, b, a];
  }

  let startingColor = [];
  let hightlightColor = [];
  let solveColor = [];
  let currentColor = [];
  let mazeGenerated = false;
  let solved = false;
  let startSolve = false;
  let current;
  p5.setup = function () {
    startingColor = getRandomColor();

    hightlightColor = getRandomColor();

    solveColor = getRandomColor();

    currentColor = startingColor;

    p5.createCanvas(400, 400);
    cols = p5.floor(p5.width / w);
    rows = p5.floor(p5.height / w);
    // frameRate(10);

    // put setup code here

    for (let i = 0; i < cols; i++) {
      for (let j = 0; j < rows; j++) {
        grid.push(new Cell(i, j, currentColor));
      }
    }
    current = grid[0];

    grid[grid.length - 1].finish = true;
  };

  //function to return 6 digit hex code for the next color in the color wheel
  function getNextColor() {
    if (currentColor === undefined) {
      currentColor = startingColor;
    } else {
      currentColor = [
        currentColor[0] + 15,
        currentColor[1],
        currentColor[2],
        currentColor[3],
      ];
      if (currentColor[0] > 255) {
        currentColor[0] -= 255;
        currentColor[0] += 1;
      }
      if (currentColor[1] > 255) {
        currentColor[1] -= 255;
        currentColor[1] += 1;
      }
      if (currentColor[2] > 255) {
        currentColor[2] -= 255;
        currentColor[2] += 1;
      }

      return currentColor;
    }
  }

  function solveMaze() {
    if (!startSolve) {
      for (let i = 0; i < grid.length; i++) {
        grid[i].visited = false;
      }
      startSolve = true;

      current = grid[0];
    }
    current.visited = true;
    current.color = solveColor;
    current.show();
    // console.log(current);
    let nextPath = current.checkWalls();
    // console.log(nextPath);
    if (current.finish) {
      solved = true;
      p5.noLoop();
      //timer to restart the maze
      setTimeout(() => {
        solveStack.length = 0;
        grid.length = 0;
        stack.length = 0;
        paths.length = 0;
        startSolve = false;
        solved = false;
        mazeGenerated = false;
        p5.setup();
        p5.loop();
      }, 1000);
    } else if (nextPath) {
      // console.log("current.finish:", current?.finish);
      nextPath.visited = true;
      nextPath.color = solveColor;
      nextPath.show();
      solveStack.push(current);
      current = nextPath;
      // nextPath = current.checkWalls();
    } else {
      // console.log("no nextPath");
      if (solveStack.length > 0) {
        current.color = startingColor;
        current.show();
        current = solveStack.pop();
        // current = solveStack.shift();
      }
    }
  }

  function generateMaze() {
    current.visited = true;

    //step 1 set it as visited
    let next = current.checkNeighbors();

    if (next) {
      next.visited = true;

      next.color = currentColor;

      //step 2 push it to the stack
      stack.push(current);
      //step 3 removeWalls
      removeWalls(current, next);
      current = next;
    } else {
      // currentColor = getNextColor();
      // stack.length > 0 ? (current = stack.pop()) : (mazeGenerated = true);
      stack.length > 0 ? (current = stack.shift()) : (mazeGenerated = true);

      // console.log(mazeGenerated);
    }

    p5.background(hightlightColor);
    for (let i = 0; i < grid.length; i++) {
      grid[i].show();
    }
    // current.highlight(hightlightColor);
  }
  function index(i, j) {
    if (i < 0 || i > cols - 1 || j < 0 || j > rows - 1) {
      return -1;
    }

    return j + i * cols;
  }

  p5.draw = function () {
    if (mazeGenerated) {
      solveMaze();
    } else {
      generateMaze();
    }
  };

  function removeWalls(a, b) {
    let x = a.i - b.i;
    if (x === 1) {
      a.walls[3] = false;
      b.walls[1] = false;
    }
    if (x === -1) {
      a.walls[1] = false;
      b.walls[3] = false;
    }
    let y = a.j - b.j;
    if (y === 1) {
      a.walls[0] = false;
      b.walls[2] = false;
    }
    if (y === -1) {
      a.walls[2] = false;
      b.walls[0] = false;
    }
  }
  function Cell(i, j, color = [255, 255, 255, 255]) {
    this.i = i;
    this.j = j;
    this.walls = [true, true, true, true]; // top, right, bottom, left
    this.visited = false;
    this.finish = false;
    this.color = color;
    this.checkWalls = function () {
      let neighbors = [];
      let paths = [];
      let top = grid[index(i, j - 1)];
      let right = grid[index(i + 1, j)];
      let bottom = grid[index(i, j + 1)];
      let left = grid[index(i - 1, j)];
      if (!current.walls[0]) {
        neighbors.push(top);
      }
      if (!current.walls[1]) {
        neighbors.push(right);
      }
      if (!current.walls[2]) {
        neighbors.push(bottom);
      }
      if (!current.walls[3]) {
        neighbors.push(left);
      }
      for (let i = 0; i < neighbors.length; i++) {
        if (!neighbors[i].visited) {
          paths.push(neighbors[i]);
        }
      }
      if (paths.length > 0) {
        // console.log("paths available:", paths);
        let next = p5.random(paths);
        return next;
      } else {
        return undefined;
      }
    };
    this.checkNeighbors = function () {
      let neighbors = [];
      let top = grid[index(i, j - 1)];
      let right = grid[index(i + 1, j)];
      let bottom = grid[index(i, j + 1)];
      let left = grid[index(i - 1, j)];
      // console.log(neighbors);
      if (top && !top.visited) {
        neighbors.push(top);
      }
      if (right && !right.visited) {
        neighbors.push(right);
      }
      if (bottom && !bottom.visited) {
        neighbors.push(bottom);
      }
      if (left && !left.visited) {
        neighbors.push(left);
      }
      if (neighbors.length > 0) {
        let r = p5.floor(p5.random(0, neighbors.length));
        return neighbors[r];

        // return neighbors[0];
      } else {
        return undefined;
      }
    };
    this.show = function () {
      let x = this.i * w;
      let y = this.j * w;
      p5.stroke(0);
      // noFill();
      // rect(x, y, w, w);
      if (this.walls[0]) {
        p5.line(x, y, x + w, y); // top
      }
      if (this.walls[1]) {
        p5.line(x + w, y, x + w, y + w); // right
      }
      if (this.walls[2]) {
        p5.line(x, y + w, x + w, y + w); // bottom
      }
      if (this.walls[3]) {
        p5.line(x, y, x, y + w); // left
      }
      if (this.visited) {
        p5.noStroke();
        p5.fill(this.color[0], this.color[1], this.color[2]);
        p5.rect(x, y, w, w);
      } else {
        p5.fill(this.color[0], this.color[1], this.color[2]);
      }
    };
    this.highlight = function (color = [255, 255, 255, 255]) {
      let x = this.i * w;
      let y = this.j * w;
      p5.noStroke();
      p5.fill(color[0], color[1], color[2]);
      p5.rect(x, y, w, w);
    }; // highlight the current cell
  } // end of Cell class
}
//call setup function

export default sketch2;

import Container from "react-bootstrap/Container";
export default function Contact() {
  return (
    <div
      id="home"
      className="bg-dark container-fluid"
      style={{ paddingTop: "25vh", paddingBottom: "5vh" }}
    >
      <Container style={{ maxWidth: "1000px" }} className="text-center p-3">
        <div className="text-white">
          <h1>
            FOR INQUIRIES &amp; QUESTIONS:
            <br />
          </h1>
          <p style={{ fontSize: "1.5rem" }}>
            <i class="fa fa-paper-plane fa-fw"></i>
            <a href="mailto:myke@myked.com">myke@myked.com</a>
            <br />
            <i class="fa fa-linkedin-square fa-fw"></i>
            <a href="https://www.linkedin.com/in/mykedixon">
              linkedin.com/in/mykedixon
            </a>
            <br />
            <i class="fa  fa-video-camera fa-fw"></i>

            <a href="https://www.imdb.com/name/nm3081444/">
              imdb.com/name/nm3081444
            </a>
            <br />
            <i class="fa  fa-file-alt fa-fw"></i>
            <a href="https://registry.jsonresume.org/gr0m17">Online Resume</a>
            <br />
            <i class="fa  fa-file-alt fa-fw"></i>
            <a href="myke_dixon_resume_2024.pdf">PDF Resume</a>
            <br />
            <br />
            <br />
            <br />
          </p>
        </div>
      </Container>
    </div>
  );
}

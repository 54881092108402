let sun;
let numPlanets = 9;
let planets = [];
let destab = 0.6;
let G = 280;
let windowWidth = 400;
let windowHeight = 400;
let sunSize = 75;
let minSize = 5;
let maxSize = 30;
let tailLength = 30;
function sketch(p5) {
  p5.setup = function () {
    p5.createCanvas(windowWidth, windowHeight);
    // put setup code here
    sun = new Body(sunSize, p5.createVector(0, 0), p5.createVector(0, 0));
    for (let i = 0; i < numPlanets; i++) {
      //planet position
      let r = p5.random(sun.r, p5.min(windowWidth / 2, windowHeight / 3));
      let theta = p5.TWO_PI;
      let planetPos = p5.createVector(r * p5.cos(theta), r * p5.sin(theta));
      //planet velocity
      let planetVel = planetPos.copy();
      planetVel.rotate(p5.HALF_PI + p5.random(-0.1 - destab, 0.1 + destab));
      planetVel.setMag(p5.sqrt((G * sun.mass) / planetPos.mag()));
      if (p5.random(1) < 0.2) {
        planetVel.mult(-1);
      }
      planets.push(new Body(p5.random(minSize, maxSize), planetPos, planetVel));
      // console.log(planets);
    }
  };

  p5.draw = function () {
    p5.translate(windowWidth / 2, windowHeight / 2);
    p5.background("#212529");
    for (let i = 0; i < planets.length; i++) {
      sun.attract(planets[i]);
      planets[i].show();
      planets[i].update();
    }
    sun.show([255, 255, 0]);
    // put drawing code here
  };

  function Body(_mass, _pos, _vel) {
    this.mass = _mass;
    this.fillcolorA = [p5.random(0, 44), p5.random(68, 108), p5.random(0, 255)];
    this.pos = _pos;
    this.vel = _vel;
    this.r = this.mass;
    this.path = [];
    this.show = function (color) {
      p5.stroke("rgba(109, 125, 154, 0)");
      let t = 0;
      p5.strokeWeight(1);
      for (let i = 0; i < this.path.length - 2; i++) {
        p5.line(
          this.path[i].x,
          this.path[i].y,
          this.path[i + 1].x,
          this.path[i + 1].y
        );
        t = t + 1 / tailLength;
        p5.stroke(`rgba(109, 125, 154, ${t})`);
      }
      p5.noStroke();
      if (color) {
        p5.fill(color);
      }
      if (!color) {
        p5.fill(this.fillcolorA);
      }
      p5.ellipse(this.pos.x, this.pos.y, this.r, this.r);
    };

    this.update = function () {
      //update position
      this.pos.x += this.vel.x;
      this.pos.y += this.vel.y;
      this.path.push(this.pos.copy());
      if (this.path.length > tailLength) {
        this.path.splice(0, 1);
      }
    };

    this.applyForce = function (f) {
      this.vel.x += f.x / this.mass; //= ma => a = f/m;
      this.vel.y += f.y / this.mass; //= ma => a = f/m;
    };
    this.attract = function (child) {
      let r = p5.dist(this.pos.x, this.pos.y, child.pos.x, child.pos.y);
      let f = this.pos.copy().sub(child.pos);
      f.setMag((G * (this.mass * child.mass)) / (r * r));
      child.applyForce(f);
    };
  }
}
export default sketch;

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import BlackJackIMG from "../img/blackjack_001.jpg";
import StarSlotsIMG from "../img/starslots_001.jpg";
import PolarBearFrenzyIMG from "../img/polarbearfrenzy_001.png";
import PrayerHelperIMG from "../img/prayerhelper_001.png";
import DontGetStabbedIMG from "../img/dontgetstabbed_001.jpg";
import MinnesotanChatIMG from "../img/minnesotanchat_001.jpg";
import PizzaDeliverySurvivorsIMG from "../img/pizzadeliverysurvivors_001.jpg";
import DuluthcomIMG from "../img/duluth_001.jpg";
import woodblindIMG from "../img/woodblind_001.jpg";
import BTJOIMG from "../img/btjo_001.jpg";
import rotaryIMG from "../img/rotary_001.jpg";
import steppermotorIMG from "../img/steppermotor_001.jpg";
import Row from "react-bootstrap/Row";

const projectData = [
  {
    title: "Pizza Delivery Survivors",
    desc: `Deliver pizzas in a zombie apocalypse with Pizza Delivery Survivors, created using Godot 4. Dodge zombies, use whimsical weapons, and survive the chaos. Currently in development, with a demo available now at the link below.`,
    footerText1: "Live Demo",
    footerURL1: "https://pizzadeliverysurvivors.com",
    footerText2: "",
    footerURL2: "",
    img: PizzaDeliverySurvivorsIMG,
  },
  {
    title: "Minnesotan.chat",
    desc: `Talk like a true Minnesotan with Minnesotan.chat, made with React and Next.js, and powered by ChatGPT. Translate your words and impress your friends or understand Uncle Bob better. Give it a try now!`,
    footerText1: "Live Site",
    footerURL1: "https://Minnesotan.chat",
    footerText2: "",
    footerURL2: "",
    img: MinnesotanChatIMG,
  },
  {
    title: "PrayerHelper.faith",
    desc: `Craft personalized prayers with Prayer Helper - a free web app built with React, Next.js, and GPT language models. No matter your faith, find your voice in prayer with our six simple questions.`,
    footerText1: "Live Site",
    footerURL1: "https://PrayerHelper.faith",
    footerText2: "",
    footerURL2: "",
    img: PrayerHelperIMG,
  },
  {
    title: "Polar Bear Frenzy",
    desc: `Polar Bear Frenzy! A Canvas game made with Javascript. Play as a polar bear trying to get fish for their family while dodging pesky penguins. Controls: left/right arrows for movement, up arrow to jump, down arrow for claw attack.`,
    footerText1: "Live Site",
    footerURL1: "http://www.myke.wtf/polarbearfrenzy/",
    footerText2: "Github Repo",
    footerURL2: "https://github.com/gr0m17/polarbearfrenzy",
    img: PolarBearFrenzyIMG,
  },
  {
    title: "Don't Get Stabbed",
    desc: `An interactive video experience I originally created for use with YouTube annotations.
    
    I recently remastered the experience with React.`,
    footerText1: "Live Site",
    footerURL1: "http://www.dontgetstabbed.online",
    footerText2: "Github Repo",
    footerURL2: "https://github.com/gr0m17/dontgetstabbed",
    img: DontGetStabbedIMG,
  },
  {
    title: "Star Slots",
    desc: `A Stardew Valley inspired slot machine I built in React with an integrated global leaderboard.`,
    footerText1: "Live Demo",
    footerURL1: "https://starslots.surge.sh/",
    footerText2: "Github Repo",
    footerURL2: "https://github.com/gr0m17/star_slots",
    img: StarSlotsIMG,
  },
  {
    title: "bigtimejazz.org",
    desc: `I refreshed an older wordpress site with a modern React single page design.

    BTJO is a non-profit and has supported the community through performance and education of the uniquely American art form of jazz since 1990.`,
    footerText1: "Live Demo",
    footerURL1: "https://btjo.surge.sh/",
    footerText2: "",
    footerURL2: "",
    img: BTJOIMG,
  },
  {
    title: "Duluth.com",
    desc: `Duluth.com was a lifestyle website I developed with WordPress.
    In the first 12 months grew monthly pageviews to 30,000+`,
    footerText1: "site is now defunct.",
    footerURL1: "https://duluth.com",
    footerText2: "",
    footerURL2: "",
    img: DuluthcomIMG,
  },
  {
    title: " Javascript BlackJack",
    desc: `A Blackjack Implementation I created with entirely in vanilla JS. With intigrated 'basic strategy' advice into the hand totals`,
    footerText1: "Live Demo",
    footerURL1: "https://myke.wtf/blackjack/",
    footerText2: "Github Repo",
    footerURL2: "https://github.com/gr0m17/blackjack",
    img: BlackJackIMG,
  },
  {
    title: "Rotary Cellphone",
    desc: `I turned a Rotary telephone into a cellphone using a 3g chipset and an arduino.`,
    footerText1: "Video Demo",
    footerURL1: "https://myke.wtf/adafruit_interview/",
    footerText2: "Github Repo",
    footerURL2: "https://github.com/gr0m17/rotary_cellphone_dialer",
    img: rotaryIMG,
  },
  {
    title: "The Stepper Motor Music Machine",
    desc: `I turned 8 Stepper motors, a custom designed and 3d printed mount, and 2 stepper controllers into an 8 voice music machine.
    
    62k followers and growing on TikTok.`,
    footerText1: "Death by Glamour from Undertale",
    footerURL1:
      "https://www.tiktok.com/@steppermotor/video/6967179681036537094",
    footerText2: "TikTok profile",
    footerURL2: "https://www.tiktok.com/@steppermotor",
    img: steppermotorIMG,
  },
];

export default function Projects() {
  return (
    <div style={{ paddingTop: "10vh", paddingBottom: "5vh" }}>
      <Container>
        <p className="text-center align-middle display-1 p-3">
          Recent Projects
        </p>
        <Row className="g-3" xs={1} sm={1} md={2} lg={2} xl={3}>
          {projectData.map((project) => {
            return (
              <Col>
                <Card className="h-100">
                  <a href={project.footerURL1}>
                    <Card.Img variant="top" src={project.img} />
                  </a>
                  <Card.Body>
                    <Card.Title>{project.title}</Card.Title>
                    <Card.Text>{project.desc}</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Card.Link href={project.footerURL1}>
                      {project.footerText1}
                    </Card.Link>
                    <Card.Link href={project.footerURL2}>
                      {project.footerText2}
                    </Card.Link>
                  </Card.Footer>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Header from "./components/Header";
import Introduction from "./components/Introduction";
import Projects from "./components/Projects";
import Tools from "./components/Tools";
import Contact from "./components/Contact";
import AboutMe from "./components/AboutMe";
import "./App.css";
import ScrollSpy from "react-ui-scrollspy";
import ReactGA from "react-ga";

const App = () => {
  ReactGA.initialize("UA-63454306-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div className="app p-3">
      <Header />
      <ScrollSpy>
        <a id="home">
          <Introduction />
        </a>
        <a id="about">
          <AboutMe />
        </a>
        <a id="projects">
          <Projects />
        </a>
        <a id="contact">
          <Contact />
        </a>
      </ScrollSpy>
    </div>
  );
};

export default App;

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ReactP5Wrapper } from "react-p5-wrapper";
import sketch from "./orbitsSketch";
import sketch2 from "./mazeSketch";
const imdb = "https://www.imdb.com/name/nm3081444/";
const toldStories = "https://photo.myked.com/#motion";
const photosLink = "https://photo.myked.com/#photography";
const interactiveExperiences = "https://dontgetstabbed.online";
const whimsical = "https://myke.wtf/adafruit_interview/";

export default function AboutMe() {
  return (
    <div
      id="home"
      className="bg-dark container-fluid"
      style={{ paddingTop: "15vh", paddingBottom: "15vh" }}
    >
      <Container style={{ maxWidth: "1000px" }} className="text-center p-3">
        <div className="text-white">
          <Row xs={1} md={1} lg={1} className="g-5">
            <Col>
              <p className="text-center align-middle">
                <span style={{ fontSize: "4em" }}>
                  <i className="fas fa-camera-retro"></i>
                </span>
                <br />
                I’ve been <a href={photosLink}>taking photos</a> for as long as
                I can remember and my first website was hosted on Geocities. I
                learned how to write html by reading a book at the public
                library. I also coded it there.
                <br />
                (they had free internet access. In the 90s!)
                <br />
              </p>
            </Col>
            <Col>
              <p className="text-center align-middle">
                <ReactP5Wrapper sketch={sketch} />
                Fast-forward to today:
                <br /> I’ve worked in glamorous Hollywood{" "}
                <a href={imdb}>managing data</a> for major motion pictures,{" "}
                <a href={toldStories}>told stories</a> for a midwest ad agency,
                and even developed and launched a lifestyle branded website for
                my hometown.
                <br />
              </p>
            </Col>
            <Col>
              <p className="text-center align-middle">
                <ReactP5Wrapper sketch={sketch2} />
                <br />I am currently a Developer at{" "}
                <a href="http://carmichaellynch.com">Carmichael Lynch</a>.<br />{" "}
                My current focus is on building{" "}
                <a href={interactiveExperiences}>interactive experiences</a> and
                <a href={whimsical}> whimsical hardware</a> development.
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
